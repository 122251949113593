import type {GridRowSelectionModel} from '@mui/x-data-grid'
import {baseApi} from './base'
import type {UserInterface} from '../shared/interfaces/user.ts'
import type {
	BaseFetchResultInterface,
	BaseFetchResultsInterface, BasicObjectInterface,
} from '../shared/interfaces/base.ts'
import type {ManufacturerPublicInterface} from '../shared/interfaces/manufacturerPublic.ts'
import type {AuthResponseInterface} from '../shared/interfaces/auth.ts'

const userAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getCurrentUser: builder.query<UserInterface, void>({
			query: () => ({url: 'current_user/'}),
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getCustomers: builder.query<BasicObjectInterface[], void>({
			query: () => 'customers/',
			transformResponse: (response: BaseFetchResultsInterface<BasicObjectInterface>) => response.results,
		}),
		getManufacturers: builder.query<BasicObjectInterface[], void>({
			query: () => 'manufacturers/',
			transformResponse: (response: BaseFetchResultsInterface<BasicObjectInterface>) => response.results,
		}),
		getManufacturerPublic: builder.query<ManufacturerPublicInterface, { id: string }>({
			query: ({id}) => `manufacturers/${id}/public/`,
			transformResponse: (response: BaseFetchResultInterface<ManufacturerPublicInterface>) => response.result,
		}),
		getUsersByTeam: builder.query<BaseFetchResultsInterface<UserInterface>, { limit: number, offset: number }>({
			query: ({limit, offset}) => `users/?limit=${limit}&offset=${offset}`,
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getUserRoles: builder.query<string[], void>({
			query: () => 'users/available_roles/',
			transformResponse: (response: BaseFetchResultsInterface<string>) => response.results,
		}),
		createUser: builder.mutation<AuthResponseInterface, { creds: FormData }>({
			query: ({creds}) => ({
				url: 'register_user/',
				method: 'POST',
				body: creds,
				headers: {Authorization: ''},
			}),
		}),
		createUserAsAdmin: builder.mutation<{ message: string, result: UserInterface }, { submitData: { first_name: FormDataEntryValue | null, last_name: FormDataEntryValue | null, email: FormDataEntryValue | null, roles: { [key: string]: boolean } } }>({
			query: ({submitData}) => ({
				url: 'users/',
				method: 'POST',
				body: submitData,
			}),
		}),
		changeUsersStatus: builder.mutation<{ message: string }, { users: GridRowSelectionModel, method: string }>({
			query: (data) => ({
				url: 'users/change_status/',
				method: 'POST',
				body: data,
			}),
		}),
		updateUser: builder.mutation({
			query: (creds) => ({
				url: 'current_user/',
				method: 'POST',
				body: creds,
			}),
		}),
		agreeToTerms: builder.mutation({
			query: (body) => ({
				url: 'current_user/',
				method: 'POST',
				body,
			}),
		}),
		addUserToTeam: builder.mutation({
			query: (userID) => ({
				url: `users/${userID}/add_to_group/`,
				method: 'POST',
			}),
		}),
		updateUserRoles: builder.mutation<{ message: string }, { userID: number, roles: string[] }>({
			query: ({userID, roles}) => ({
				url: `users/${userID}/update_user_roles/`,
				method: 'POST',
				body: {roles},
			}),
		}),
		resetPassword: builder.mutation<{ message: string }, { creds: FormData }>({
			query: ({creds}) => ({
				url: 'reset_password/',
				method: 'POST',
				body: creds,
				headers: {Authorization: ''},
			}),
		}),
		updatePassword: builder.mutation({
			query: (body) => ({
				url: 'current_user_update_password/',
				method: 'POST',
				body,
			}),
		}),
		auth: builder.mutation({
			query: (body) => ({
				url: 'auth/',
				method: 'POST',
				body,
				headers: {Authorization: ''},
			}),
		}),
		getUser: builder.mutation<UserInterface, void>({
			query: () => ({url: 'current_user/'}),
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		requestAccountActivation: builder.mutation<{ message: string }, void>({
			query: () => ({
				url: 'current_user_activation_request/',
				method: 'POST',
			}),
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetUsersByTeamQuery,
	useGetUserRolesQuery,
	useResetPasswordMutation,
	useUpdatePasswordMutation,
	useCreateUserMutation,
	useCreateUserAsAdminMutation,
	useUpdateUserMutation,
	useUpdateUserRolesMutation,
	useChangeUsersStatusMutation,
	useAuthMutation,
	useAgreeToTermsMutation,
	useGetUserMutation,
	useRequestAccountActivationMutation,
} = userAPI
