import type {
	ReactNode, FC, ReactElement, SetStateAction, Dispatch,
} from 'react'
import {
	createContext, useContext, useState, useEffect, useMemo,
} from 'react'
import type {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query'
import type {SerializedError} from '@reduxjs/toolkit'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import type {AuthContextProps} from './AuthProvider'
import {useAuthContext} from './AuthProvider'
import {useGetUserMutation} from '../../apis/user'
import type {UserInterface} from '../../shared/interfaces/user'
import {
	setPreferredLanguage, setPreferredTheme, setPreferredLocale,
} from '../../redux/slices/user.ts'

interface UserProviderProps {
    children?: ReactNode,
}

export interface UserContextProps {
	currentUser: UserInterface | null,
	setCurrentUser: Dispatch<SetStateAction<UserInterface | null>>,
	setLoadingUser: Dispatch<SetStateAction<boolean>>,
	loadingUser?: boolean,
	userError?: FetchBaseQueryError | SerializedError | undefined,
	welcomeSkipped?: boolean,
	setWelcomeSkipped: Dispatch<SetStateAction<boolean>>,
}

const UserContext = createContext<UserContextProps | null>(null)

const useUserContext = (): UserContextProps | null => useContext(UserContext)

const UserProvider: FC<UserProviderProps> = ({children}): ReactElement => {
	const {pathname} = window.location
	const allowedPaths = ['compare-pdfs', 'orders', 'dashboard', 'create-account', 'reset-password', 'authentication']
	const {userIsLoggedIn, logoutUser} = useAuthContext() as AuthContextProps
	const [loadingUser, setLoadingUser] = useState<boolean>(true)
	const [welcomeSkipped, setWelcomeSkipped] = useState<boolean>(false)
	const [currentUser, setCurrentUser] = useState<UserInterface | null>(null)
	const navigate = useNavigate()
	const [getUser] = useGetUserMutation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (userIsLoggedIn) {
			getUser()
				.unwrap()
				.then((user) => {
					setCurrentUser(user)
					if (user && user.userprofile) {
						dispatch(setPreferredTheme(user.userprofile.preferred_theme))
						dispatch(setPreferredLanguage(user.userprofile.preferred_language))
						dispatch(setPreferredLocale(user.userprofile.preferred_locale))
					}
					// If the user is inactive, redirect to the inactive user page
					if (user.is_active === false) {
						navigate('/inactive_user/')
					}
					// If there are no tenants, redirect to the create organization page
				})
				.catch((error) => {
					// If the token is invalid
					if (error.data?.detail === 'Invalid token.') {
						logoutUser()
						navigate('/login')
					} else if (error.data?.detail === 'User inactive or deleted.') {
						navigate('/inactive_user/?status=user-not-found')
					}
				})
			setLoadingUser(false)
		} else {
			setCurrentUser(null)
			setLoadingUser(false)
			if (!allowedPaths.includes(pathname.split('/')[1])) {
				navigate('/login')
			}
		}
	}, [userIsLoggedIn])

	return (
		<UserContext.Provider
			value={
				useMemo(() => ({
					currentUser,
					setCurrentUser,
					loadingUser,
					setLoadingUser,
					welcomeSkipped,
					setWelcomeSkipped,
				}), [currentUser, setCurrentUser, loadingUser, setLoadingUser, welcomeSkipped, setWelcomeSkipped])
			}
		>
			{children}
		</UserContext.Provider>
	)
}

export {
	useUserContext, UserProvider, UserContext,
}
