import type {GridRowSelectionModel} from '@mui/x-data-grid'
import type {
	BaseSyntheticEvent, Dispatch, SetStateAction, MutableRefObject,
} from 'react'
import {format, formatDistance} from 'date-fns'
import type {DatumValue} from '@nivo/core'
import type {
	ScaleTime,
	ScaleLinear,
	ScaleBand,
} from 'd3'
import {
	extent,
	scaleTime,
	scaleLinear,
	scaleBand,
	min,
	max,
	select,
} from 'd3'
import type {Theme} from '@mui/material/styles'
import {
	cyan, deepPurple, purple, grey, blue,
} from '@mui/material/colors'
import {
	de, enUS, es, fr,
} from 'date-fns/locale'
import type {TFunction} from 'react-i18next'
import type {DaysHoursMinInterface} from './shared/interfaces/daysHoursMin'
import type {ProgramInterface} from './shared/interfaces/program'
import type {ViewInterface} from './shared/interfaces/view'
import type {UserInterface} from './shared/interfaces/user'
import type {SliceTooltipTracesInterface, TraceInterface} from './shared/interfaces/trace'
import type {OverlayedSignalResponseInterface} from './shared/interfaces/signal'
import type {LegendItemInterface} from './shared/interfaces/nivoGraph'
import {timeParser} from './shared/constants/d3Graph'
import type {CockpitWarningInterface} from './shared/interfaces/cockpitWarning.ts'

type LanguageCode = 'de' | 'en' | 'es' | 'fr';

const getTokenFromURL = (): string | null => {
	try {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const token = urlParams.get('token')
		return token || null
	} catch (error) {
		return null
	}
}

export const getToken = (): string | null => {
	const localToken = localStorage.getItem('token')
	if (localToken) {
		return localToken
	}
	return getTokenFromURL()
}

export const getDefaultPreferredLanguage = (): string => {
	const preferredLanguage = localStorage.getItem('mbai-preferred-language')
	return preferredLanguage || 'en'
}

export const getRolesObject = (roles: string[]): { [key: string]: boolean } => {
	const roleObject: { [key: string]: boolean } = {}
	roles.forEach((item: string) => {
		roleObject[item] = true
	})
	return roleObject
}

export const getUserFullName = (user: { first_name: string, last_name: string } | UserInterface): string | null => {
	try {
		const firstName = user.first_name ? user.first_name : ''
		const lastName = user.last_name ? user.last_name : ''
		if (firstName && lastName) {
			return `${firstName} ${lastName}`
		} if (firstName) {
			return firstName
		} if (lastName) {
			return lastName
		}
		return ''
	} catch (e) {
		return null
	}
}

export const getSubstring = (str: string, startMarker: string, endMarker: string): string => {
	const start = str.indexOf(startMarker)
	if (start === -1) return '0'
	const end = str.indexOf(endMarker, start)
	if (end === -1) return '0'
	return str.substring(start + 1, end)
}

// Returns units of time based on an ISOString duration
export const ISOStringToUnits = (ISOString: string): DaysHoursMinInterface => {
	let days = getSubstring(ISOString, 'P', 'D')
	if (days.includes('M')) {
		[, days] = days.split('M')
	} else if (days.includes('Y')) {
		[, days] = days.split('Y')
	}
	let hours = getSubstring(ISOString, 'T', 'H')
	let minutes = getSubstring(ISOString, 'H', 'M')

	// Handle seconds correctly
	const secondsMatch = ISOString.match(/(\d+(\.\d+)?)S/)
	let seconds = '0'
	if (secondsMatch) {
		const secondsString = secondsMatch[1]
		const secondsNumber = parseFloat(secondsString)
		seconds = Math.round(secondsNumber).toString()
	}

	// If any of the values is 0, return an empty string
	seconds = seconds === '0' ? '' : seconds
	minutes = minutes === '0' ? '' : minutes
	hours = hours === '0' ? '' : hours
	days = days === '0' ? '' : days

	return {
		days,
		hours,
		minutes,
		seconds,
	}
}

export const getUrlParams = (arr: string[] | GridRowSelectionModel | ProgramInterface[], param: string): string => {
	const newArray: string[] = []
	arr.forEach((item, key) => {
		newArray[key] = `${param}=${item}`
	})
	return newArray.join('&')
}

export const sleep = (ms: number): Promise<typeof setTimeout> => new Promise((resolve) => setTimeout(resolve, ms))

export const getUniqueValuesFromArray = <T, Key extends keyof T>(array: T[], key?: Key): T[] | T[Key][] => {
	let newArray
	let finalArray: T[] | T[Key][]

	// 1: Get simple arrays
	if (key) {
		// If there is a key, transform
		newArray = Array.from(new Set(array.map((item) => item[key])))
	} else {
		newArray = Array.from(new Set(array))
	}

	// 2: Built the objects back using the simple arrays
	if (key) {
		finalArray = newArray.map((item) => array.find((i: T) => i[key] === item)) as Array<T>
	} else {
		finalArray = newArray
	}
	return finalArray
}

// Searches for searchTerm, in searchList (array of objects) using searchProperties
export const searchItems = <T>(searchTerm: string, searchList: T[], searchProperties: string[]): T[] => {
	let newList
	if (!searchTerm) {
		newList = searchList
	} else {
		newList = searchList.filter((item) => searchProperties.some((s: string) => {
			if (item[s as keyof typeof item]) {
				return (item[s as keyof typeof item] as string).toLowerCase().includes(searchTerm.toLowerCase())
			}
			return null
		}))
	}
	return newList
}

// Compares two objects with similar models to determine if they are equal.
export const areObjectsEqual = <T>(firstObject: T, secondObject: T): boolean => {
	for (const property in firstObject) {
		if (typeof firstObject[property] === 'string') {
			if (secondObject && firstObject[property] !== secondObject[property]) {
				return false
			}
		} else if (typeof firstObject[property] === 'object') {
			if (secondObject && !areObjectsEqual(firstObject[property], secondObject[property])) {
				return false
			}
		}
	}
	return true
}

// Compares two objects with similar models to determine if they are equal.
export const areViewsEqual = (firstObject: ViewInterface, secondObject: ViewInterface): boolean => {
	let property: keyof typeof firstObject
	for (property in firstObject) {
		if (property === 'selected_batches' || property === 'selected_programs') {
			if (firstObject[property].length !== secondObject[property].length) {
				return false
			}
		} else if (firstObject[property] !== secondObject[property]) {
			return false
		}
	}
	return true
}

export const getLongestString = (array: string[]): string => array.reduce((a, b) => (a.length > b.length ? a : b))

export const updateCount = (viewId: string, increaseCount: Function): void => {
	increaseCount({viewId})
		.unwrap()
		.then(() => {
		})
		.catch((error: Error) => {
			console.error(error)
		})
}

export const areObjectKeysEqual = (firstObject: object, secondObject: object): boolean => {
	const keys1 = Object.keys(firstObject)
	const keys2 = Object.keys(secondObject)

	if (keys1.length !== keys2.length) {
		return false
	}

	for (const key of keys1) {
		if (!keys2.includes(key)) {
			return false
		}

		const value1 = firstObject[key as keyof typeof firstObject]
		const value2 = secondObject[key as keyof typeof secondObject]

		if (typeof value1 === 'object' && typeof value2 === 'object') {
			if (!areObjectKeysEqual(value1, value2)) {
				return false
			}
		}
	}

	return true
}

export const handleValidation = (val: string): boolean => {
	const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i
	return regex.test(val)
}

export const handleBasicInputChange = (event: BaseSyntheticEvent, setNewValue: Dispatch<SetStateAction<FormDataEntryValue>>): void => {
	const {value} = event.target
	setNewValue(value)
}

// format "00:01:18.240966"
export const hourToString = (hour: string): string => {
	const hourArray = hour.split(':')
	let hourString
	if (hourArray[0] === '00') {
		hourArray.shift()
		hourString = `${hourArray[0]}m ${Math.round(Number(hourArray[1])).toString()}s`
	} else {
		hourString = `${hourArray[0]}h ${hourArray[1]}m ${Math.round(Number(hourArray[2])).toString()}s`
	}
	return hourString
}

interface OptionsProps {
    showDate?: boolean,
    showYear?: boolean,
    showTime?: boolean,
    showSeconds?: boolean,
}

export const getFormattedDate = (
	date: Date | string | DatumValue,
	user: UserInterface | null,
	{
		showDate = true,
		showYear = false,
		showTime = false,
		showSeconds = false,
	}: OptionsProps,
): string => {
	if (!date) return ''

	let newDate: Date
	let dayMonth: string
	let hourMinute: string
	let amPm: string

	if (user && user.userprofile) {
		switch (user.userprofile.preferred_locale) {
			case 'en':
				dayMonth = 'MM/dd'
				hourMinute = 'hh:mm'
				amPm = ' aaa'
				break
			case 'en-gb':
				dayMonth = 'dd/MM'
				hourMinute = 'HH:mm'
				amPm = ''
				break
			case 'de':
				dayMonth = 'dd.MM'
				hourMinute = 'HH:mm'
				amPm = ''
				break
			default:
				dayMonth = 'MM/dd'
				hourMinute = 'hh:mm'
				amPm = ''
				break
		}
	} else {
		dayMonth = 'dd/MM'
		hourMinute = 'HH:mm'
		amPm = ''
	}

	const year = showYear && showDate && user?.userprofile?.preferred_locale === 'de' ? '.Y' : showYear && showDate ? '/Y' : ''
	const time = showDate && showTime ? ` | ${hourMinute}` : showTime ? hourMinute : ''
	const seconds = showSeconds ? ':ss' : ''
	const dayMonthString = showDate ? dayMonth : ''
	const amPmString = showTime ? amPm : ''

	// Get the new date
	if (!Number.isNaN(new Date(date).getDate())) {
		newDate = new Date(date)
	} else {
		newDate = date as Date
	}

	const formatString = dayMonthString + year + time + seconds + amPmString

	return format(newDate, formatString)
}

const LANGUAGE_LOCALE_MAP: Record<LanguageCode, Locale> = {
	de,
	en: enUS,
	es,
	fr,
}

export const lastSeenFunction = (lastSeen: Date, user: UserInterface | null): string => {
	const lastSeenDate = new Date(lastSeen)
	const newDate = new Date()
	const difference = newDate.getTime() - lastSeenDate.getTime()
	const daysDifference = difference / (1000 * 60 * 60 * 24)
	const preferred_language = user?.userprofile?.preferred_language || 'en'
	const locale = LANGUAGE_LOCALE_MAP[preferred_language as LanguageCode] || enUS
	if (daysDifference < 1) {
		return formatDistance(new Date(lastSeenDate), new Date(), {addSuffix: true, locale})
	}
	return getFormattedDate(lastSeenDate, user, {
		showYear: true,
		showTime: true,
		showSeconds: true,
	})
}

// sorts the list based on the sortBy value
export const filterValueFunction = (
	sortBy: string,
	showSort: (data: ViewInterface[]) => void,
	list: ViewInterface[],
): void => {
	if (sortBy === 'most_viewed_by_user') {
		showSort(list.slice().sort((a, b) => {
			if (a && b && a.my_views !== undefined && b.my_views !== undefined) {
				return b.my_views - a.my_views
			}
			return 0
		}))
	}
	if (sortBy === 'most_popular') {
		showSort(list.slice().sort((a, b) => {
			if (a && b && a.total_views !== undefined && b.total_views !== undefined) {
				return b.total_views - a.total_views
			}
			return 0
		}))
	}
	if (sortBy === 'a_to_z') {
		showSort(list.slice().sort((a, b) => {
			if (a && b && a.name && b.name) {
				return (a.name as string).toLowerCase().localeCompare((b.name as string).toLowerCase())
			}
			return 0
		}))
	}
}

export const formatHeaderXValue = (value: number | Date, user: UserInterface): string => {
	let xValue: string
	try {
		if (typeof value === 'number') {
			xValue = `Minute: ${Math.round((value + Number.EPSILON) * 100) / 100}`
		} else {
			xValue = getFormattedDate(value, user, {
				showYear: true,
				showTime: true,
				showSeconds: true,
			})
		}
		return xValue
	} catch (error) {
		console.error(error)
		return ''
	}
}

export const findClosestCircle = (xPosition: number, d3SVGRef: MutableRefObject<HTMLDivElement | null>): SVGCircleElement | null => {
	const {current} = d3SVGRef
	if (!current) return null
	const svg = select(current)
	const allCircles = svg.selectAll('.main-graph-data-point')
	let closestCircle = null
	let minDifference = Number.MAX_SAFE_INTEGER

	allCircles.each((_, i, nodes) => {
		const currentCx = parseFloat(select(nodes[i]).attr('cx') || '0')
		const difference = Math.abs(currentCx - xPosition)

		if (difference < minDifference) {
			minDifference = difference
			closestCircle = nodes[i]
		}
	})
	return closestCircle
}

export const getTracesFromXPosition = (
	traceData: (TraceInterface | OverlayedSignalResponseInterface)[],
	d3SVGRef: MutableRefObject<HTMLDivElement | null>,
	xPosition: number,
	xScale: ScaleLinear<number, number> | ScaleTime<number, number>,
	batchesStates: LegendItemInterface[],
): SliceTooltipTracesInterface[] => traceData.flatMap((trace) => trace.data.filter((data) => {
	const parsedDataX = timeParser(data.x) // Convert back to string if needed
	const dataX = parsedDataX ? xScale(parsedDataX) : xScale(parseFloat(data.x))
	return Math.abs(dataX - xPosition) < 0.01
}).map((data) => {
	const {current} = d3SVGRef
	if (!current) return null
	const svg = select(current)
	const circles = svg.selectAll('.main-graph-data-point')
	// eslint-disable-next-line func-names
	const currentColorElement = circles.filter(function () {
		const traceId = (trace as TraceInterface).uuid || trace.id
		return select(this).attr('data-name') === traceId
	}).node()

	const currentColor = currentColorElement ? select(currentColorElement).attr('fill') || 'black' : 'black'

	// Find the corresponding object in batchesStates
	const matchingBatch = batchesStates.find(
		(batch) => batch.batchId === trace.id || batch.batchId === (trace as TraceInterface).uuid,
	)

	// Check if matchingBatch exists and its 'selected' property is true
	if (matchingBatch && matchingBatch.selected) {
		return {
			trace,
			value: data.y,
			color: currentColor,
		}
	}
	return null
})
	.filter(Boolean) as SliceTooltipTracesInterface[])

export const getYDomainBasedOnTraces = (
	yScale: ScaleLinear<number, number> | ScaleBand<string> | null,
	allCircles: SVGCircleElement[],
	xDomain: [number, number],
	dataType?: string,
): [number, number] | null => {
	if (!yScale) return null
	const valuesInRange = allCircles.flatMap((circle) => {
		const newCx = parseFloat(circle.getAttribute('cx') || '0')
		if (newCx >= xDomain[0] && newCx <= xDomain[1]) {
			let newCYNumber: number = 0
			const newCy = parseFloat(circle.getAttribute('cy') || '0')
			// If using a band scale, map string values to numeric values
			if (dataType && dataType === 'string') {
				newCYNumber = (yScale as ScaleBand<string>)(newCy.toString()) as number
			} else if (typeof yScale === 'function') {
				newCYNumber = (yScale as ScaleLinear<number, number>).invert(newCy)
			}
			return newCYNumber
		}
		return null
	}).filter((value) => value !== null) as number[]

	const minVal = min(valuesInRange) || 0
	const maxVal = max(valuesInRange) || 0

	return [minVal, maxVal]
}

export const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1)

export const getXValues = (traceData: (TraceInterface | OverlayedSignalResponseInterface)[]): string[] => (traceData ? traceData.map((item) => item.data.map((i) => i.x)).flat() : [])

export const getYValues = (traceData: (TraceInterface | OverlayedSignalResponseInterface)[]): (string | number)[] => (traceData ? traceData.map((item) => item.data.map((i) => i.y)).flat() : [])

export const getXDomain = (traceData: (TraceInterface | OverlayedSignalResponseInterface)[]): [Date | number, Date | number] | null => {
	if (!traceData || (traceData && traceData.length === 0)) return null
	let newXDomain: [Date | number, Date | number]
	const xValues = getXValues(traceData)
	if (timeParser(xValues[0])) {
		newXDomain = extent(xValues.map((item) => timeParser(item) as Date)) as [Date, Date]
	} else {
		const numberValues = xValues.map((item) => parseInt(item))
		newXDomain = extent(numberValues) as [number, number]
	}
	return newXDomain
}

export const getYDomain = (traceData: (TraceInterface | OverlayedSignalResponseInterface)[]): [number, number] | null => {
	if (!traceData || (traceData && traceData.length === 0)) return null
	let newCYNumber: [number, number]
	const yValues = getYValues(traceData)
	if (typeof yValues[0] === 'string') {
		newCYNumber = extent(yValues.map((item) => parseInt(item as string))) as [number, number]
	} else {
		newCYNumber = extent(yValues.map((item) => item as number)) as [number, number]
	}
	return newCYNumber
}

export const getXScale = (
	traceData: (TraceInterface | OverlayedSignalResponseInterface)[],
	range: [number, number],
	domain: [number | Date, number | Date] | null,
): ScaleTime<number, number> | ScaleLinear<number, number> | null => {
	if (!domain || !traceData || traceData.length === 0) return null
	const values = getXValues(traceData)
	let newXScale: ScaleTime<number, number> | ScaleLinear<number, number>
	if (values.length > 0 && timeParser(values[0])) {
		newXScale = scaleTime().domain(domain)
			.range(range)
	} else {
		newXScale = scaleLinear().domain(domain)
			.range(range)
	}
	return newXScale
}

export const getYScale = (
	traceData: (TraceInterface | OverlayedSignalResponseInterface)[],
	range: [number, number],
	domain: [number, number] | null,
): ScaleLinear<number, number> | ScaleBand<string> | null => {
	if (!domain || !traceData || traceData.length === 0) return null
	const values = getYValues(traceData)
	const isNumericData = typeof values[0] === 'number'
	let newYScale: ScaleLinear<number, number> | ScaleBand<string>
	if (isNumericData) {
		newYScale = scaleLinear().domain(domain)
			.range(range)
	} else {
		const uniqueStrings = Array.from(new Set(values as string[]))
		newYScale = scaleBand().domain(uniqueStrings)
			.range(range)
	}
	return newYScale
}

export const formatRollupValue = (value: string | number): string | number => {
	const numValue = Number(value)

	if (Number.isNaN(numValue)) {
		return value
	}

	const roundedValue = Math.round(numValue * 100) / 100

	if (Number.isInteger(roundedValue)) {
		return roundedValue.toString()
	}

	return roundedValue.toFixed(2)
}

export const mapWarningToColor = (isWarning: boolean | null): string => {
	if (isWarning === null) {
		// Turning grey as this means that no data is found in backend for the signal on which the warning is based
		return 'text.disabled'
	}
	return isWarning ? 'warning.main' : 'success.main'
}

export const getColorFromProgramClassification = (classification: string, theme: Theme): string => {
	switch (classification) {
		case 'recipe':
			return theme.palette.success.main
		case 'cip':
			return purple[500]
		case 'sip':
			return deepPurple[500]
		case 'maintenance':
			return blue[500]
		case 'setup':
			return cyan[500]
		case 'downtime':
			return grey[500]
		case 'unplanned_downtime':
			return theme.palette.error.main
		case 'unassigned':
			return theme.palette.text.disabled
		default:
			return theme.palette.text.disabled
	}
}

export const classificationTranslationMapping = (classification: string, t: TFunction): string => {
	switch (classification) {
		case 'recipe':
			return t('programs.classification_choices.recipe')
		case 'cip':
			return t('programs.classification_choices.cip')
		case 'sip':
			return t('programs.classification_choices.sip')
		case 'maintenance':
			return t('programs.classification_choices.maintenance')
		case 'setup':
			return t('programs.classification_choices.setup')
		case 'downtime':
			return t('programs.classification_choices.downtime')
		case 'unplanned_downtime':
			return t('programs.classification_choices.unplanned_downtime')
		case 'unassigned':
			return t('programs.classification_choices.unassigned')
		default:
			return classification
	}
}

export const transformStartDate = (dateString: string, summarizeBy: string): string => {
	const date = new Date(dateString)

	if (summarizeBy === 'day') {
		date.setHours(0, 0, 0, 0) // Set to closest past midnight
	} else if (summarizeBy === 'week' || summarizeBy === 'calendar_week') {
		const day = date.getDay() // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
		const diff = (day === 0 ? 6 : day - 1) // If it's Sunday (0), go back 6 days; otherwise, go back to Monday
		date.setDate(date.getDate() - diff)
		date.setHours(0, 0, 0, 0) // Set time to midnight
	}

	return date.toISOString()
}

export const filterWarnings = (warnings: CockpitWarningInterface[]): CockpitWarningInterface[] => warnings?.filter((warning) => warning.eval_result.is_warning === true)
