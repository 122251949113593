import type {FC, ReactElement} from 'react'
import {
	StrictMode, useEffect, useState,
} from 'react'
import {
	Box,
	Typography,
	Toolbar, AppBar, Card, CardContent, CardHeader,
	Button,
} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'
import {
	Outlet, useLocation, useNavigate,
} from 'react-router-dom'
import {lightTheme} from '../../containers/App/themes/lightTheme'
import {darkTheme} from '../../containers/App/themes/darkTheme'
import {useCustomTranslation} from '../hooks/useCustomTranslation'
import whiteLogo from '../../assets/mbai-official-logo-favicon-white-2023.png'
import darkLogo from '../../assets/mbai-official-logo-favicon-purple-2023.png'
import FormTitle from '../components/auth/forms/components/FormTitle'
import ErrorBoundary from '../components/ErrorBoundary'

const AuthTemplate: FC = (): ReactElement => {
	const [t] = useCustomTranslation('login')
	const [tErrors] = useCustomTranslation('errors')
	const themeString = localStorage.getItem('mbai-preferred-theme')
	const theme = themeString === 'dark' ? darkTheme : lightTheme
	const [pageTitle, setPageTitle] = useState<string>('')
	const [formTitle, setFormTitle] = useState<string>('')
	const [url, setUrl] = useState<string>('')
	const navigate = useNavigate()

	const location = useLocation()

	useEffect(() => {
		switch (location.pathname) {
			case '/login':
				setPageTitle(t('sign_up'))
				setFormTitle(t('title'))
				setUrl('/create-account')
				break
			case '/create-account':
				setPageTitle(t('sign_in'))
				setFormTitle(t('create_account'))
				setUrl('/login')
				break
			case '/reset-password':
				setPageTitle(t('sign_in'))
				setFormTitle(t('reset_password'))
				setUrl('/login')
				break
			case '/create-organization':
				setPageTitle(t('sign_in'))
				setFormTitle('Create New Organization')
				setUrl('/login')
				break
			default:
				setPageTitle(t('sign_up'))
				setFormTitle(t('title'))
				setUrl('/create-account')
		}
		if (location.pathname.includes('authentication')) {
			setPageTitle(t('sign_in'))
			setFormTitle('Authenticating...')
			setUrl('/login')
		}
	}, [location])

	const mainLogo = themeString === 'dark' ? whiteLogo : darkLogo

	return (
		<StrictMode>
			<ThemeProvider theme={theme}>
				<AppBar
					position="fixed"
					sx={
						{
							backgroundColor: 'background.default',
							borderBottom: 1,
							borderColor: 'divider',
						}
					}
					data-testid="mbai-main-auth-template-component"
				>
					<Toolbar>
						<Box
							sx={
								{
									flexGrow: 1,
									display: 'flex',
									alignItems: 'center',
								}
							}
						>
							<img
								src={mainLogo}
								alt="Main Logo" // Provide meaningful alt text
								style={
									{maxWidth: '150px'}
								}
							/>
							<Box
								sx={
									{
										textAlign: 'right',
										flexGrow: 1,
									}
								}
							>
								<Button
									variant="outlined"
									onClick={() => navigate(url)}
								>
									{pageTitle}
								</Button>
							</Box>
						</Box>
					</Toolbar>
				</AppBar>
				<Toolbar />
				<Box
					sx={
						{
							backgroundColor: 'background.default',
							px: 2,
							pt: 5,
						}
					}
				>
					{/* JSX structure seems to be broken here with missing Grid components or incorrect structure */}
					<Card
						sx={
							{
								mx: 'auto',
								position: 'relative',
								maxWidth: '600px',
								backgroundColor: 'background.paper',
							}
						}
					>
						<CardHeader
							title={<FormTitle label={formTitle} />} // Assuming FormTitle is a valid component
							sx={
								{
									borderBottom: 1,
									borderColor: 'divider',
								}
							}
						/>
						<CardContent
							sx={
								{pt: 3}
							}
						>
							<ErrorBoundary
								fallbackComponent={
									(
										<Typography
											variant="body1"
											color="error"
										>
											{tErrors('error_boundary_generic')}
										</Typography>
									)
								}
							>
								<Outlet />
							</ErrorBoundary>
						</CardContent>
					</Card>
				</Box>
				<Box
					sx={
						{
							textAlign: 'center',
							flexGrow: 1,
							backgroundColor: 'background.default',
							position: 'fixed',
							bottom: 0,
							p: 2,
							borderTop: 1,
							borderTopColor: 'divider',
							width: '100%',
						}
					}
				>
					<a
						href="https://montblanc.ai/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Typography
							variant="caption"
							component="span"
							sx={
								{
									ml: 0.5,
									color: 'text.disabled',
									textTransform: 'capitalize',
								}
							}
						>
							{t('_home')}
						</Typography>
					</a>
					<a
						href="https://montblanc.ai/privacy-policy"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Typography
							variant="caption"
							component="span"
							sx={
								{
									ml: 2,
									color: 'text.disabled',
									textTransform: 'capitalize',
								}
							}
						>
							{t('privacy_policy')}
						</Typography>
					</a>
				</Box>
			</ThemeProvider>
		</StrictMode>
	)
}

export default AuthTemplate
