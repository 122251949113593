import type {FC, ReactElement} from 'react'
import {
	useState, useEffect, useRef, useCallback,
} from 'react'
import {Box} from '@mui/material'
import {useSelector} from 'react-redux'
import type {RootState} from '../../containers/App/store'
import {darkTheme} from '../../containers/App/themes/darkTheme'
import {lightTheme} from '../../containers/App/themes/lightTheme'

type Props = {
    children: ReactElement | ReactElement[] | null,
    height?: number | string,
	maxHeight?: number | string,
}

const ScrollbarContainer: FC<Props> = (
	{
		children,
		maxHeight,
		...props
	},
): ReactElement => {
	const contentRef = useRef<HTMLDivElement>(null)
	const scrollTrackRef = useRef<HTMLDivElement>(null)
	const scrollThumbRef = useRef<HTMLDivElement>(null)
	const observer = useRef<ResizeObserver | null>(null)
	const [scrollBarBackground, setScrollBarBackground] = useState<string>('')
	const scrollBarWidth: string = '5px'
	const [thumbHeight] = useState(20)
	const currentTheme = useSelector((state: RootState) => state.user.preferredTheme)

	useEffect(() => {
		const theme = currentTheme === 'dark' ? darkTheme : lightTheme
		const {background} = theme.palette
		setScrollBarBackground(background.scrollbar)
	}, [currentTheme])

	const handleMouseLeave = (): void => {
		const thumb = scrollThumbRef.current
		setTimeout(() => {
			if (thumb) {
				thumb.style.opacity = '0'
			}
		}, 3000)
	}

	const handleThumbPosition = useCallback(() => {
		if (
			!contentRef.current
            || !scrollTrackRef.current
            || !scrollThumbRef.current
		) {
			return
		}
		const {scrollTop: contentTop, scrollHeight: contentHeight} = contentRef.current
		const {clientHeight: trackHeight} = scrollTrackRef.current
		const newHeight = contentRef.current.offsetHeight / contentRef.current.scrollHeight
		let newTop = (contentTop / contentHeight) * trackHeight
		newTop = Math.min(newTop, trackHeight)
		const thumb = scrollThumbRef.current
		thumb.style.height = `${newHeight * 100}%`
		thumb.style.top = `${newTop}px`
		thumb.style.opacity = '1'
	}, [])
	// If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
	useEffect(() => {
		if (contentRef.current && scrollTrackRef.current) {
			const ref = contentRef.current
			ref.addEventListener('scroll', handleThumbPosition)
			return () => {
				observer.current?.unobserve(ref)
			}
		}
		return () => {}
	}, [])

	return (
		<Box
			className="custom-scrollbars__container"
			data-testid="mbai-main-scrollbar-container-component"
			sx={
				{position: 'relative'}
			}
		>
			<Box
				className="scrollableBox"
				ref={contentRef}
				{...props}
				sx={
					{
						overflowY: 'scroll',
						overflowX: 'hidden',
						maxHeight: maxHeight || '',
					}
				}
				onMouseLeave={handleMouseLeave}
			>
				{children}
			</Box>
			<Box
				ref={scrollTrackRef}
				className="custom-scrollbars__track-and-thumb"
				sx={
					{
						display: 'block',
						height: `${props.height}`,
						position: 'absolute',
						width: scrollBarWidth,
						top: 0,
						right: 0,
						marginRight: '2px',
					}
				}
			>
				<Box
					className="custom-scrollbars__track"
					sx={
						{
							bottom: '0',
							cursor: 'pointer',
							position: 'absolute',
							top: '0',
							width: scrollBarWidth,
						}
					}
				/>
				<Box
					className="custom-scrollbars__thumb"
					ref={scrollThumbRef}
					sx={
						{
							height: `${thumbHeight}px`,
							position: 'absolute',
							width: scrollBarWidth,
							background: scrollBarBackground,
							borderRadius: '5px',
							opacity: '0',
						}
					}
				/>
			</Box>
		</Box>
	)
}

export default ScrollbarContainer
