import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {GridRowSelectionModel} from '@mui/x-data-grid'
import {getUrlParams} from '../../util-functions'
import type {BatchInitialReduxStateInterface, BatchInterface} from '../../shared/interfaces/batch'

const initialState: BatchInitialReduxStateInterface = {
	selected: [],
	filterURL: '',
	batchesURL: '',
	pageBatch: 0,
	programBatch: 0,
	focusedBatchId: null,
}

export const batches = createSlice({
	name: 'batches',
	initialState,
	reducers: {
		setGlobalSelectedBatches: (state, action: PayloadAction<string[] | BatchInterface[] | GridRowSelectionModel>) => {
			if (action.payload) {
				const batchesIds = action.payload.map((item) => {
					if (typeof item === 'string') {
						return item
					}
					return (item as BatchInterface).id
				})
				const filterURL = getUrlParams(batchesIds, 'id')
				const batchesURL = getUrlParams(batchesIds, 'batch')
				return {
					...state,
					selected: batchesIds,
					filterURL,
					batchesURL,
				}
			}
			return {
				...state,
				selected: [],
				filterURL: '',
				batchesURL: '',
			}
		},
		setPageBatch: (state, action: PayloadAction<number>) => ({
			...state,
			pageBatch: action.payload,
		}),
		setPageProgram: (state, action: PayloadAction<number>) => ({
			...state,
			programBatch: action.payload,
		}),
		setFocusedBatchId: (state, action: PayloadAction<string | null>) => ({
			...state,
			focusedBatchId: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {
	setGlobalSelectedBatches,
	setPageBatch,
	setPageProgram,
	setFocusedBatchId,
} = batches.actions
export default batches.reducer
