import type {FC, ReactNode} from 'react'
import {
	useEffect, useRef, useCallback,
} from 'react'
import {Box} from '@mui/material'

interface Props {
    children: ReactNode,
    maxWidth?: string,
}

const HorizontalScrollingContainer: FC<Props> = ({children, maxWidth = '100%'}) => {
	const horizonatlScrollingContainerRef = useRef<HTMLElement | null>(null)
	const horizonatlScrollingContainerChildRef = useRef<HTMLElement | null>(null)
	const checkOverflow = useCallback((element: HTMLElement, otherElement: HTMLElement) => {
		// Store the current overflow style
		const currentOverflow = element.style.overflow

		// Temporarily set overflow to 'hidden' to force scrollWidth/scrollHeight calculation
		if (!currentOverflow || currentOverflow === 'visible') {
			element.style.overflow = 'hidden'
		}

		// Check if content overflows by comparing client dimensions with scroll dimensions
		const isOverflowing = otherElement.clientWidth < element.scrollWidth

		// Restore the original overflow style
		element.style.overflow = currentOverflow

		return isOverflowing
	}, [])

	useEffect(() => {
		const {current: childScrolling} = horizonatlScrollingContainerChildRef
		const {current: mainScrolling} = horizonatlScrollingContainerRef
		if (!mainScrolling || !childScrolling) return undefined
		const shadowLeft = mainScrolling.querySelector('.shadow-left')
		const shadowRight = mainScrolling.querySelector('.shadow-right')
		const myObserver = new ResizeObserver(
			(entries: ResizeObserverEntry[]) => {
				entries.forEach(() => {
					const hasOverflow = checkOverflow(childScrolling, mainScrolling)
					if (hasOverflow) {
						(shadowRight as HTMLElement).style.display = 'block'
					} else {
						(shadowRight as HTMLElement).style.display = 'none'
					}
				})
			},
		)
		myObserver.observe(childScrolling as HTMLDivElement)

		childScrolling.addEventListener('scroll', () => {
			if (shadowLeft && shadowRight) {
				if (childScrolling.scrollLeft === 0) {
					(shadowLeft as HTMLElement).style.display = 'none'
				} else {
					(shadowLeft as HTMLElement).style.display = 'block'
				}
				if (Math.round(childScrolling.scrollLeft + childScrolling.clientWidth) >= childScrolling.scrollWidth) {
					(shadowRight as HTMLElement).style.display = 'none'
				} else {
					(shadowRight as HTMLElement).style.display = 'block'
				}
			}
		})
		return () => {
			try {
				myObserver.unobserve(childScrolling as HTMLDivElement)
			} catch (e) {
				console.error('Wrong Div')
			}
		}
	}, [])

	return (
		<Box
			sx={
				{
					overflow: 'hidden',
					position: 'relative',
					maxWidth,
				}
			}
			data-testid="mbai-main-horizontal-scrolling-container"
			ref={horizonatlScrollingContainerRef}
		>
			<Box
				className="shadow-left"
				sx={
					{
						position: 'absolute',
						top: 0,
						bottom: 0,
						width: '10px',
						height: '100%',
						background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0))',
						left: 0,
						display: 'none',
					}
				}
			/>
			<Box
				sx={
					{
						position: 'absolute',
						top: 0,
						bottom: 0,
						width: '10px',
						height: '100%',
						background: 'linear-gradient(-90deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0))',
						right: 0,
						display: 'none',
					}
				}
				className="shadow-right"
			/>
			<Box
				sx={
					{
						overflowX: 'scroll',
						display: 'flex',
						'::-webkit-scrollbar': {height: '4px'},
						'::-webkit-scrollbar-thumb': {background: 'transparent'},
						'&:hover': {
							'::-webkit-scrollbar': {height: '4px'},
							'::-webkit-scrollbar-thumb': {
								transition: 'background 2s ease-in-out',
								background: '#888',
								borderRadius: '5px',
							},
						},
					}
				}
				ref={horizonatlScrollingContainerChildRef}
			>
				{children}
			</Box>
		</Box>
	)
}

export default HorizontalScrollingContainer
