import type {FC, ReactElement} from 'react'
import {Button, Tooltip} from '@mui/material'
import {NavLink} from 'react-router-dom'

interface ActionButtonProps {
    dataName?: string,
    dataCy: string,
	link: string,
    title: string,
	icon: ReactElement,
	onClick?: () => void,
	target?: string,
}

const ActionButton: FC<ActionButtonProps> = (
	{
		dataName,
		dataCy,
		link,
		title,
		icon,
		onClick,
		target,
	},
): ReactElement => (
	<Tooltip
		title={title}
		arrow
	>
		<Button
			data-testid="mbai-main-action-button-component"
			component={NavLink}
			data-name={dataName}
			data-cy={dataCy}
			to={link}
			variant="outlined"
			sx={
				{
					width: '100%',
					'&:hover': {color: 'primary.main'},
				}
			}
			onClick={onClick}
			target={target}
		>
			{icon}
		</Button>
	</Tooltip>
)

export default ActionButton
