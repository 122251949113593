import type {FC, ReactElement} from 'react'
import {
	StrictMode, useEffect, lazy, Suspense,
} from 'react'
import {
	Route, Routes, useLocation, useNavigate,
} from 'react-router-dom'

// Import templates
import AuthTemplate from '../../shared/templates/AuthTemplate'
import DashTemplate from '../../shared/templates/DashTemplate'
import MinimizedTemplate from '../../shared/templates/MinimizedTemplate'
import type {UserContextProps} from './UserProvider'
import {useUserContext} from './UserProvider'
import type {AuthContextProps} from './AuthProvider'
import {useAuthContext} from './AuthProvider'
import SettingsLoader from '../../shared/components/loaders/dashboards/Settings'
import LinesOverviewLoader from '../../shared/components/loaders/dashboards/LinesOverview'
import LineSignalsLoader from '../../shared/components/loaders/dashboards/LineSignals'
import DashboardManagementLoader from '../../shared/components/loaders/dashboards/DashboardManagement'
import LineDashboardLoader from '../../shared/components/loaders/dashboards/LineDashboard'
import LineManagementLoader from '../../shared/components/loaders/dashboards/LineManagement'
import BatchTraceabilityLoader from '../../shared/components/loaders/dashboards/BatchTraceability'
import AuthTemplateLoader from '../../shared/components/loaders/dashboards/AuthTemplate'
import MinimizedTemplateLoader from '../../shared/components/loaders/dashboards/MinimizedTemplate'
import DashTemplateLoader from '../../shared/components/loaders/dashboards/DashTemplate'
import BasicDashboardContainer from '../../shared/components/BasicDashboardContainer.tsx'
import RowDataProvider from '../Dashboards/EdgeApi/shared/rowDataProvider.tsx'
import AnomalyComponent from '../Dashboards/Anomalies/index.tsx'
import AddConnectionEdgeApiPage from '../Dashboards/EdgeApi/components/connections/addConnectionForm/AddConnectionEdgeApiPageF.tsx'
// import EquipmentDashboard from '../Dashboards/EquipmentDashboard'

// Lazily load components
const LinesOverview = lazy(() => import('../Dashboards/LinesOverview'))
const LineDashboard = lazy(() => import('../Dashboards/LineDashboard'))
const LineSignals = lazy(() => import('../Dashboards/LineSignals'))
const LineManagement = lazy(() => import('../Dashboards/LineManagement'))
const LineMaintenance = lazy(() => import('../Dashboards/LineMaintenance'))
const Settings = lazy(() => import('../Dashboards/Settings/Settings'))
const InactiveUser = lazy(() => import('../../shared/pages/InactiveUser'))
const BatchTraceability = lazy(() => import('../Dashboards/BatchTraceability'))
const DashboardManagement = lazy(() => import('../Dashboards/DashboardManagement'))
const SignalsDashboard = lazy(() => import('../Dashboards/SignalsDashboard'))
const LoginForm = lazy(() => import('../../shared/components/auth/forms/LoginForm'))
const RegisterForm = lazy(() => import('../../shared/components/auth/forms/RegisterForm'))
const ResetPasswordForm = lazy(() => import('../../shared/components/auth/forms/ResetPasswordForm'))
const NoPage = lazy(() => import('../../shared/pages/NoPage'))
const ComparePDFs = lazy(() => import('../../shared/pages/ComparePDFs'))
const StartupKitOnboarding = lazy(() => import('../../shared/pages/StartupKitOnboarding'))
const Welcome = lazy(() => import('../../shared/pages/Welcome'))
const EdgeApi = lazy(() => import('../Dashboards/EdgeApi'))
const Cockpit = lazy(() => import('../../shared/components/maps/Cockpit'))
const CreateTenantForm = lazy(() => import('../../shared/components/auth/forms/CreateTenantForm'))
const Signals = lazy(() => import('../Dashboards/LineManagement/components/Signals'))
const EquipmentManagement = lazy(() => import('../Dashboards/EquipmentManagement'))
const EquipmentDashboard = lazy(() => import('../Dashboards/EquipmentDashboard'))

const Router: FC = (): ReactElement => {
	const auth = localStorage.getItem('token') ? localStorage.getItem('token') : null
	const {loadingUser} = useUserContext() as UserContextProps
	const {userIsLoggedIn} = useAuthContext() as AuthContextProps

	// This component is used to scroll to the top of the page when the URL changes
	const ScrollToTop = (): null => {
		const {pathname} = useLocation()

		useEffect(() => {
			window.scrollTo(0, 0)
		}, [pathname])

		return null
	}

	const navigate = useNavigate()

	// This is used to handle the brower back button click
	// The default event caused some errors. With this snippet
	// We're using frontend/client navigation
	useEffect(() => {
		const handlePopState = (event: PopStateEvent): void => {
			event.preventDefault()
			navigate(-1)
		}

		window.addEventListener('popstate', handlePopState)

		return () => {
			window.removeEventListener('popstate', handlePopState)
		}
	}, [navigate])

	return (
		<StrictMode>
			<ScrollToTop />
			<Routes>
				{
					auth && loadingUser ? (
						<Route
							path="/*"
							element={<DashTemplateLoader />}
						/>
					) : userIsLoggedIn && !loadingUser ? (
						<>
							<Route
								path="/"
								element={
									(
										<Suspense fallback={<DashTemplateLoader />}>
											<DashTemplate />
										</Suspense>
									)
								}
							>
								<Route
									path="connections"
									element={
										(
											<Suspense fallback={<DashboardManagementLoader />}>
												<RowDataProvider>
													<EdgeApi />
												</RowDataProvider>
											</Suspense>
										)
									}
								/>
								<Route
									path="anomalies"
									element={
										(
											<Suspense fallback={<DashboardManagementLoader />}>
												<AnomalyComponent />
											</Suspense>
										)
									}
								/>
								<Route
									path="all-signals"
									element={
										(
											<Suspense fallback={<DashboardManagementLoader />}>
												<BasicDashboardContainer>
													<Signals
														title={false}
														height="calc(100vh - 165px)"
													/>
												</BasicDashboardContainer>
											</Suspense>
										)
									}
								/>
								<Route
									path="create-organization"
									element={<CreateTenantForm />}
								/>
								<Route
									path="/"
									element={
										(
											<Suspense fallback={<LinesOverviewLoader />}>
												<LinesOverview />
											</Suspense>
										)
									}
								/>
								<Route
									path="lines_overview"
									element={
										(
											<Suspense fallback={<LinesOverviewLoader />}>
												<LinesOverview />
											</Suspense>
										)
									}
								/>
								<Route
									path="line/:id"
									element={
										(
											<Suspense fallback={<LineDashboardLoader />}>
												<LineDashboard />
											</Suspense>
										)
									}
								/>
								<Route
									path="equipment/:id"
									element={
										(
											<Suspense fallback={<LineDashboardLoader />}>
												<EquipmentDashboard />
											</Suspense>
										)
									}
								/>
								<Route
									path="equipment/:id/equipment_management"
									element={
										(
											<Suspense fallback={<LineDashboardLoader />}>
												<EquipmentManagement />
											</Suspense>
										)
									}
								/>
								<Route
									path="line/:id/signals"
									element={
										(
											<Suspense fallback={<LineSignalsLoader />}>
												<LineSignals />
											</Suspense>
										)
									}
								/>
								<Route
									path="line/:id/line_management"
									element={
										(
											<Suspense fallback={<LineManagementLoader />}>
												<LineManagement />
											</Suspense>
										)
									}
								/>
								<Route
									path="maintenance_calendar/"
									element={<LineMaintenance />}
								/>
								<Route
									path="settings"
									element={
										(
											<Suspense fallback={<SettingsLoader />}>
												<Settings />
											</Suspense>
										)
									}
								/>
								<Route
									path="/inactive_user"
									element={<InactiveUser />}
								/>
								<Route
									path="batch_traceability"
									element={
										(
											<Suspense fallback={<BatchTraceabilityLoader />}>
												<BatchTraceability />
											</Suspense>
										)
									}
								/>
								<Route
									path="dashboards"
									element={
										(
											<Suspense fallback={<DashboardManagementLoader />}>
												<DashboardManagement />
											</Suspense>
										)
									}
								/>
								<Route
									path="*"
									element={<NoPage />}
								/>
							</Route>
							<Route
								path="/"
								element={
									(
										<Suspense fallback={<MinimizedTemplateLoader />}>
											<MinimizedTemplate />
										</Suspense>
									)
								}
							>
								<Route
									path="startup-kit-onboarding"
									element={<StartupKitOnboarding />}
								/>
								<Route
									path="connections/add"
									element={<AddConnectionEdgeApiPage />}
								/>
							</Route>
							<Route
								path="welcome"
								element={<Welcome />}
							/>
						</>
					) : !userIsLoggedIn && !loadingUser ? (
						<Route
							path="/"
							element={
								(
									<Suspense fallback={<AuthTemplateLoader />}>
										<AuthTemplate />
									</Suspense>
								)
							}
						>
							<Route
								path="login"
								element={<LoginForm />}
							/>
							<Route
								path="create-account"
								element={<RegisterForm />}
							/>
							<Route
								path="reset-password"
								element={<ResetPasswordForm />}
							/>
							<Route
								path="authentication/token/handle"
								element={<LoginForm />}
							/>
						</Route>
					) : null
				}
				<Route
					path="/"
					element={
						(
							<Suspense fallback={<MinimizedTemplateLoader />}>
								<MinimizedTemplate />
							</Suspense>
						)
					}
				>
					<Route
						path="dashboard/:id"
						element={<SignalsDashboard />}
					/>
					<Route
						path="compare-pdfs"
						element={<ComparePDFs />}
					/>
					<Route
						path="cockpit"
						element={<Cockpit />}
					/>
				</Route>
			</Routes>
		</StrictMode>
	)
}

export default Router
